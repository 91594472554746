import {
  FORMATTED_CREMATION_FUNERAL_PLAN_FEE,
  FORMATTED_CREMATION_FUNERAL_PLAN_MONTHLY_FULL_FEE,
} from 'views/cremation/shared/constants'

export const UPFRONT = {
  metatitle: 'Commission Disclosure - Upfront Payment',
  metadescription: 'Commission disclosure for our funeral plan, paid upfront',
  price: FORMATTED_CREMATION_FUNERAL_PLAN_FEE,
  title: 'Commission Disclosure: Upfront Payment',
  costExplanation:
    'This is wholly attributable to the supply of your funeral plan.',
}

export const INSTALMENTS = {
  metatitle: 'Commission Disclosure - Payment in Instalments',
  metadescription:
    'Commission disclosure for our funeral plan, paid in instalments',
  price: FORMATTED_CREMATION_FUNERAL_PLAN_MONTHLY_FULL_FEE,
  title: 'Commission Disclosure: Payment in Instalments',
  costExplanation:
    'Your payments, minus the instalment fee, are wholly attributable to the supply of your funeral plan.',
}
