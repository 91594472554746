import React, { ReactElement } from 'react'
import { Grid, H, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import { FUNERAL_PLAN_EMAIL, FUNERAL_PLAN_TELEPHONE_NUMBER } from 'config'
import DefaultLayout from 'layouts/DefaultLayout'
import { formatPriceInPounds } from 'lib/formatting/pricing'
import { PRODUCTS } from 'lib/products/constants'
import {
  FORMATTED_CREMATION_DELIVERY_FEE,
  FORMATTED_CREMATION_FUNERAL_PLAN_INSTALMENT_FEE,
  FORMATTED_CREMATION_FUNERAL_PLAN_MONTHLY_INSTALMENT_FEE,
  FORMATTED_CREMATION_MEDICAL_DEVICE_REMOVAL_FEE,
  FORMATTED_CREMATION_URGENT_COLLECTION_FEE,
  FORMATTED_DIRECT_CREMATION_PRICE,
} from 'views/cremation/shared/constants'
import { INSTALMENTS, UPFRONT } from './data'

const StyledList = styled.ul`
  color: ${COLOR.BLACK};
  list-style-type: disc;
  margin-left: ${GTR.M};
  line-height: 1.5;

  > li {
    margin-bottom: ${GTR.XS};
  }
`

const ComissionDisclosure = ({
  paymentType,
}: {
  paymentType: 'instalments' | 'upfront'
}): ReactElement => {
  const DATA = paymentType === 'instalments' ? INSTALMENTS : UPFRONT
  return (
    <DefaultLayout
      title={DATA.metatitle}
      description={DATA.metadescription}
      product={PRODUCTS.FUNERAL_PLAN}
      email={FUNERAL_PLAN_EMAIL}
      footerShowFuneralPlansLegalInformation
      footerType="compact"
      telephoneNumber={FUNERAL_PLAN_TELEPHONE_NUMBER}
      usePhoneNumberTracking={false}
    >
      <Grid container>
        <Grid.Item spanFromM={9} spanFromL={6}>
          <H size="XL" decorative>
            {DATA.title}
          </H>
          <Wrapper separator>
            <P color={COLOR.BLACK}>
              The total cost of your individual funeral plan is{' '}
              <strong>{DATA.price}</strong>
            </P>
            {paymentType === 'instalments' && (
              <P color={COLOR.BLACK}>
                That’s {FORMATTED_CREMATION_FUNERAL_PLAN_MONTHLY_INSTALMENT_FEE}{' '}
                per month for 24 months, including a{' '}
                {FORMATTED_CREMATION_FUNERAL_PLAN_INSTALMENT_FEE} instalment
                fee.
              </P>
            )}
            <P color={COLOR.BLACK}>
              {DATA.costExplanation} From this total cost, £910 is placed in an
              independent trust. This amount is used to provide the covered
              individual with the direct cremation funeral when it’s needed.
            </P>
            <P color={COLOR.BLACK}>
              Any additional money is retained by Farewill Funerals Limited to
              cover costs such as marketing, setting up and administering your
              plan.
            </P>
            <P color={COLOR.BLACK}>
              To help you compare prices, if you contacted Farewill Funerals
              Limited to arrange a funeral today, the cost of a direct cremation
              would start at {FORMATTED_DIRECT_CREMATION_PRICE}. Depending on
              the circumstances, additional costs could include:
            </P>
            <StyledList>
              <li>
                {FORMATTED_CREMATION_URGENT_COLLECTION_FEE}, an urgent
                collection fee, if we need to bring someone into our care
                urgently because they died somewhere without a mortuary, like a
                home or care home
              </li>
              <li>
                {FORMATTED_CREMATION_MEDICAL_DEVICE_REMOVAL_FEE}, to remove any
                medical devices, like a pacemaker
              </li>
              <li>
                {FORMATTED_CREMATION_DELIVERY_FEE}, to hand deliver the ashes
                anywhere in mainland England or Wales
              </li>
            </StyledList>

            <P color={COLOR.BLACK}>
              The total cost, including all of these services, would be{' '}
              {formatPriceInPounds(
                [
                  FORMATTED_DIRECT_CREMATION_PRICE,
                  FORMATTED_CREMATION_URGENT_COLLECTION_FEE,
                  FORMATTED_CREMATION_MEDICAL_DEVICE_REMOVAL_FEE,
                  FORMATTED_CREMATION_DELIVERY_FEE,
                ].reduce((sum, price) => sum + parseInt(price.slice(1), 10), 0)
              )}
              .
            </P>
          </Wrapper>
        </Grid.Item>
      </Grid>
    </DefaultLayout>
  )
}
export default ComissionDisclosure
